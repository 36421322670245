<template>
  <aside>
    <header>
      <h2>Launch HiThrive</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <p>It's time to go live! Launch HiThrive so your team can start using their recognition channels.</p>

      <div class="switch-wrap">
        <ht-switch v-model="include_message" />
        <span>Send onboarding message?</span>
      </div>
      <div class="message-preview" v-if="include_message">
        <div class="inner-message" v-if="team_metadata.disable_shoutouts === true">
          <img src="@/assets/hithrive-icon.png" height="40" />
          <div class="message">
            <div class="d-flex align-items-end">
              <h4>HiThrive <small>APP</small></h4>
              <span class="timestamp">10:00 AM</span>
            </div>
            <p>
              Hey there! I'm HiThrive, the employee recognition app. <span class="mention">@{{me.name}}</span> just installed me to help us celebrate each other for all of the meaningful contributions that you make.
            </p>

            <p>
              <a href="#" class="custom-msg-btn" @click.prevent="add_custom_msg = !add_custom_msg">{{add_custom_msg ? `(remove custom message)` : `(add custom message)`}}</a>
              <textarea rows="5" class="form-control" v-model="custom_msg" v-if="add_custom_msg" ref="custommsg"></textarea>
            </p>
          </div>
        </div>
        <div class="inner-message" v-else>
          <img src="@/assets/hithrive-icon.png" height="40" />
          <div class="message">
            <div class="d-flex align-items-end">
              <h4>HiThrive <small>APP</small></h4>
              <span class="timestamp">10:00 AM</span>
            </div>
            <p>
              Hey there! I'm HiThrive, the employee recognition app. <span class="mention">@{{me.name}}</span> just installed me to help your team recognize each other for all of the meaningful contributions that you make.
            </p>

            <p>
              <a href="#" class="custom-msg-btn" @click.prevent="add_custom_msg = !add_custom_msg">{{add_custom_msg ? `(remove custom message)` : `(add custom message)`}}</a>
              <textarea rows="5" class="form-control" v-model="custom_msg" v-if="add_custom_msg" ref="custommsg"></textarea>
            </p>

            <p><strong>Here's how it works:</strong></p>
            <p class="indent">
              <strong>Send a shoutout!</strong>
              <br>
              Use my slash command <span class="callout">/shoutout @name Great job today!</span> to send a shoutout. Include multiple <span class="callout">@names</span> to send your shoutout to more than one person.
            </p>

            <p class="indent">
              <strong>Show some love!</strong>
              <br>
              Add reactions to shoutouts and awards as a fun way to show your support to your fellow team members. 
            </p>
          </div>
        </div>
      </div>
    </main>

    <footer>
      <button :class="['btn', 'btn-green', 'btn-sm', {'loading': loading}]" :disabled="loading" @click="sendMsgs">Launch HiThrive <i v-html="$emoji(':rocket:')"></i></button>
    </footer>
  </aside>
</template>

<script>
export default {
  computed: {
    me() {
      return this.$store.state.user;
    },
    team_metadata() {
      return this.$store.state.team.metadata;
    }
  },
  watch: {
    add_custom_msg(val) {
      if(val === false) this.custom_msg = '';
      else this.$nextTick( () => { this.$refs.custommsg.focus() } )
    }
  },
  data() {
    return {
      add_custom_msg: false,
      custom_msg: '',
      loading: false,
      include_message: false
    }
  },
  methods: {
    close() {
      this.$emit('done')
    },
    async sendMsgs() {
      this.loading = true;
      await this.$api.Team.send_onboarding_message(this.include_message, this.custom_msg)
      await this.$store.dispatch('teamMetadata', {introduction: true})
      this.loading = false;

      this.$emit('done', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  > i {
    font-style: normal;
  }
}

.message-preview {
  background: #fff;
  box-shadow: rgba(0,0,0,0.1) 0 5px 10px;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  overflow: hidden;
  margin: 10px 0 0;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 230px;
    background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%);
  }

  .inner-message {
    display: flex;

    .custom-msg-btn {
      font-size: 12px;
      line-height: 12px;
    }

    > img {
      border-radius: 4px;
      display: block;
    }

    > .message {
      padding: 0 0 0 10px;
      h4 {
        font-weight: 700;
        font-size: 16px;
        margin: 0;

        > small {
          background: $light;
          border-radius: 4px;
          line-height: 1em;
          font-size: 10px;
          font-weight: 700;
          color: $muted-text;
          padding: 1px 2px;
          vertical-align: middle;
        }

        + span {
          font-size: 10px;
          display: block;
          margin: 0 0 0 10px;
        }
      }

      p {
        &.indent {
          position: relative;
          padding: 0 0 0 12px;

          &:before {
            content: '';
            display: block;
            position: absolute;

            width: 4px;
            top: 0;
            bottom: 0;
            left: 0;
            background: $light;
            border-radius: 10px;
          }
        }

        > .mention {
          background: #EAF5F9;
          color: #2E629E;
          border-radius: 2px;
          padding: 2px 3px;
          display: inline-block;
          line-height: 1em;
        }

        > .callout {
          background: #f5f5f5;
          color: #CD365C;
          border-radius: 2px;
          padding: 2px 3px;
          display: inline-block;
          line-height: 1em;
        }
      }
    }
  }

}
</style>